import App from './App';
//import './i18n';
import * as serviceWorker from './serviceWorker';
// import HertzAI from './components/HertzAI'
import { ParallaxProvider } from 'react-scroll-parallax';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

serviceWorker.unregister();
ReactDOM.render(
  <Suspense fallback={''}>
    {/* <ParallaxProvider> */}
    <App />
    {/* </ParallaxProvider> */}
  </Suspense>,
  // <HertzAI/>,
  document.getElementById('root')
);

// ReactDOM.render(

//     <ParallaxProvider>
//     <App />
//     </ParallaxProvider>,
//   // <HertzAI/>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
