import Navbar from './components/navbar';
import ScrollToTop from './utils/ScrollToTop';

import React, {Suspense, lazy} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import {useLocalStorage, useEffectOnce} from 'react-use';
import HeaderMulti from './pages/Layouts/header-multi';
//import './App.scss';
import ResponsiveNavbar from './components/responsiveNavbar';
import homeBusiness from './components/home-multipurpose';
import SignInSide from './components/SignInSide';
import DeleteUserPage from './components/DeleteUserPage';
import {useAuth} from './components/context/auth';
import {AuthContext} from './components/context/auth';
//export const AuthContext = React.createContext(); // added this

// import DiscoverPotential from './pages/SubPages/Multipurpose/discoverPotential';

const Home = lazy(() =>
  import('./components/home' /* webpackChunkName: "Home" */)
);
const DeepDive = lazy(() =>
  import('./components/deepdive' /* webpackChunkName: "DeepDive" */)
);
const FAQ = lazy(() =>
  import('./components/faq' /* webpackChunkName: "FAQ" */)
);
const Demographics = lazy(() =>
  import('./components/demographics' /* webpackChunkName: "PatientDB" */)
);
const State = lazy(() =>
  import('./components/state' /* webpackChunkName: "State" */)
);
const Essentials = lazy(() =>
  import('./components/essentials' /* webpackChunkName: "Essentials" */)
);
const Hertz = lazy(() =>
  import('./components/hertz' /* webpackChunkName: "HertzSamplePage" */)
);
const Curriculai = lazy(() =>
  import(
    './components/curriculai' /* webpackChunkName: "ConsearchSamplePage" */
  )
);
const Consearch = lazy(() =>
  import('./components/consearch' /* webpackChunkName: "ConsearchSamplePage" */)
);
const RegisterClient = lazy(() =>
  import(
    './components/RegisterClient' /* webpackChunkName: "RegisterClientPage" */
  )
);
const ReviewAssessment = lazy(() =>
  import(
    './components/reviewAssessment' /* webpackChunkName: "ReviewAssessmentPage" */
  )
);
const Prompt = lazy(() =>
  import(
    './components/prompt' /* webpackChunkName: "ReviewAssessmentPage" */
  )
);
const CreateAssessment = lazy(() =>
  import(
    './components/createAssessment' /* webpackChunkName: "ReviewAssessmentPage" */
  )
);
const CreateBook = lazy(() =>
  import(
    './components/createBook' /* webpackChunkName: "ReviewAssessmentPage" */
  )
);
const CreateCourse = lazy(() =>
  import(
    './components/createCourse' /* webpackChunkName: "ReviewAssessmentPage" */
  )
);

const PrivacyBlog = lazy(() =>
  import(
    './pages/blogs/privacyBlog' /* webpackChunkName: "ReviewAssessmentPage" */
  )
);
const ReasonAiBlog = lazy(() =>
  import(
    './pages/blogs/reasonAiBlog' /* webpackChunkName: "ReviewAssessmentPage" */
  )
);
const TeacherSignUp = lazy(() =>
  import(
    './components/TeacherSignUp' /* webpackChunkName: "RegisterClientPage" */
  )
);
const TeacherSignIn = lazy(() =>
  import(
    './components/TeacherSignIn' /* webpackChunkName: "RegisterClientPage" */
  )
);
const Teacherhome = lazy(() =>
  import(
    './components/TeacherLanding/TeacherHome' /* webpackChunkName: "RegisterClientPage" */
  )
);
const DemoPage = lazy(() =>
  import('./components/DemoPage' /* webpackChunkName: "RegisterClientPage" */)
);
const Pupit = lazy(() =>
  import('./components/PupitAi' /* webpackChunkName: "RegisterClientPage" */)
);
const About = lazy(() =>
  import(
    './pages/SubPages/Multipurpose/about-us' /* webpackChunkName: "RegisterClientPage" */
  )
);
const DeleteUser = lazy(() =>
  import('./components/DeleteUserPage' /* webpackChunkName: "Delete User" */)
);
const CancellationRefund = lazy(() =>
  import('./components/Cancellation&RefundPolicy' /* webpackChunkName: "Delete User" */)
);
const TermCondition = lazy(() =>
  import('./components/Terms&Conditions' /* webpackChunkName: "Delete User" */)
);

const SignIn = lazy(() =>
  import('./components/signIn' /* webpackChunkName: "RegisterClientPage" */)
);
const Cortext = lazy(() =>
  import('./components/cortext' /* webpackChunkName: "CortextSamplePage" */)
);
const HomeBusiness = lazy(() =>
  import(
    './components/home-multipurpose' /* webpackChunkName: "ConsearchSamplePage" */
  )
);

const Recap = lazy(() =>
  import('./components/recap' /* webpackChunkName: "ConsearchSamplePage" */)
);

const DiscoverPotential = lazy(() =>
  import('./pages/SubPages/Multipurpose/discoverPotential')
);

const Pricing = lazy(() =>
  // import(
  //   './pages/SubPages/Multipurpose/pricing' /* webpackChunkName: "Pricing" */
  // )
  import('./components/Pricing')
);
const Partner = lazy(() =>
  // import(
  //   './pages/SubPages/Multipurpose/pricing' /* webpackChunkName: "Pricing" */
  // )
  import('./components/Partner')
);
const Plan = lazy(() => import('./components/Plan'));
const PaymentSuccess = lazy(() => import('./components/PhonePay/PaymentSuccess'));
const PaymentFailure = lazy(() => import('./components/PhonePay/PaymentFailure'));
// const CheckPaymentStatus = lazy(() => import('./components/PhonePay/CheckPaymentStatus'));
const PendingPaymentPage = lazy(() => import('./components/PhonePay/PendingPaymentPage'));
 

const CoolParallax = lazy(() => import('./components/CoolParallax'));

const schemaMarkup = {
  '@context': 'http://schema.org/',
  '@type': 'NGO',
  name: 'Coronavirus Outbreak in India: Latest Map and Case Count',
  alternateName: 'COVID-19 Tracker',
  url: 'https://www.covid19india.org/',
  image: 'https://www.covid19india.org/thumbnail.png',
};

// initializing the state
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

// reducer logic
const reducer = (state, action) => {
  console.log('Entered reducer.!!' + action.type);
  console.log('local user-before -> ' + localStorage.getItem('user'));
  console.log('action.payload');
  console.log(action.payload);
  console.log('JSON.stringify(action.payload.access_token)');
  console.log(JSON.stringify(action.payload.access_token));
  switch (action.type) {
    case 'LOGIN':
      //localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem('user', 'rand_user1');
      localStorage.setItem(
        'token',
        JSON.stringify(action.payload.access_token)
      );
      localStorage.setItem('isAuthenticated', true);
      state = JSON.parse(
        JSON.stringify({
          user: 'GI',
          token: 'random_token',
          isAuthenticated: true,
        })
      );
      console.log('state object being returned..!');
      console.log(state);
      return state;
    // return {
    //   ...state,
    //   isAuthenticated: true,
    //   user: action.payload.user,
    //   token: action.payload.access_token,
    // };
    case 'LOGOUT':
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

function App() {
  React.useEffect(() => {
    console.log('render!');

    var dt = {user: 'GI', token: 'random_token', isAuthenticated: true};
    console.log('The state values before dispatch !!');
    console.log(state);
    dispatch({
      type: 'LOGIN',
      payload: dt,
    });
  }, []);

  const {t} = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  // const [authed, setAuthed] = React.useState(false);

  function PrivateRoute({component: Component, authed, ...rest}) {
    //const {isAuthenticated} = React.useContext(AuthContext);
    console.log('Entered private route !');
    console.log('state.isAuthenticated -> ' + state.isAuthenticated);
    console.log('state.isAuthenticated -> ' + state.isAuthenticated);
    console.log('authed -> ' + authed);
    return (
      <Route
        {...rest}
        render={(props) =>
          state.isAuthenticated === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{pathname: '/login/teacher', state: {from: props.location}}}
            />
          )
        }
      />
    );
  }

  function PrivateRoute2({component: Component, ...rest}) {
    const {authTokens} = useAuth();
    console.log('Insie private router 2');
    return (
      <Route
        {...rest}
        render={(props) =>
          authTokens ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login/teacher',
                state: {referer: props.location},
              }}
            />
          )
        }
      />
    );
  }

  const [darkMode, setDarkMode] = useLocalStorage('darkMode', false);
  const [isThemeSet] = useLocalStorage('isThemeSet', false);

  useEffectOnce(() => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      !isThemeSet
    ) {
      setDarkMode(true);
    } else if (
      window.matchMedia &&
      !window.matchMedia('(prefers-color-scheme: dark)').matches &&
      !isThemeSet
    ) {
      setDarkMode(false);
    }
  });

  React.useEffect(() => {
    if (darkMode) {
      document.querySelector('body').classList.add('dark-mode');
    } else {
      document.querySelector('body').classList.remove('dark-mode');
    }
  }, [darkMode]);

  const LoginContainer = () => (
    <div className="container">
      {/* <Route exact path="/" render={() => <Redirect to="/login" />} /> */}
      {/* <Route path="/login" component={pricing} /> */}
    </div>
  );

  const [authTokens, setAuthTokens] = React.useState(
    localStorage.getItem('authTokens') || ''
  );

  const setTokens = (data) => {
    localStorage.setItem('tokens', JSON.stringify(data));
    setAuthTokens(data);
  };

  return (
    // <AuthContext.Provider
    //   value={{
    //     state,
    //     dispatch
    //   }}
    // >
    <AuthContext.Provider value={{authTokens, setAuthTokens: setTokens}}>
      <div className="App">
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(schemaMarkup)}
          </script>
        </Helmet>

        <Router>
          {/* <ScrollToTop /> */}
          <Suspense fallback={<div className="lazy"></div>}>
            <Route
              render={({location}) => (
                <React.Fragment>
                  {/* <ResponsiveNavbar
                  pages={pages}
                  darkMode={darkMode}
                  setDarkMode={setDarkMode}
                /> */}
                  <Switch location={location}>
                    {/* <Route exact path="/(login)" component={LoginContainer}/> */}
                    <Route
                      exact
                      path="/"
                      render={(props) => <Recap {...props} />}
                    />
                    {/* <Route exact path="/aboutus" render={(props) => <HomeBusiness pageName="aboutus" {...props} />} /> */}
                    {/* <Route exact path="/features" render={(props) => <HomeBusiness pageName="features" {...props} />} /> */}
                    {/* <Route exact path="/services" render={(props) => <HomeBusiness pageName="services" {...props} />} /> */}
                    {/* <Route exact path="/cta" render={(props) => <HomeBusiness pageName="cta" {...props} />} /> */}
                    {/* <Route exact path="/testimonial" render={(props) => <HomeBusiness pageName="testimonial" {...props} />} /> */}
                    {/* <Route exact path="/team" render={(props) => <HomeBusiness pageName="team" {...props} />} /> */}
                    {/* <Route exact path="/pricing" render={(props) => <HomeBusiness pageName="pricing" {...props} />} /> */}
                    <Route
                      exact
                      path="/contactUs"
                      render={(props) => <SignInSide {...props} />}
                    />
                    {/* <Route exact path="/client" render={(props) => <HomeBusiness pageName="client" {...props} />} /> */}
                    <Route
                      exact
                      path="/products/cortext"
                      render={(props) => <Cortext {...props} />}
                    />
                    <Route
                      exact
                      path="/products/recap"
                      render={(props) => <Recap {...props} />}
                    />
                    {/* <Route exact component={Recap} path="/products/recap" /> */}
                    <Route
                      exact
                      path="/products/consearch"
                      render={(props) => <Consearch {...props} />}
                    />
                    {/* <Route exact path="/products/curriculai" render={(props) => <Curriculai  {...props} />} /> */}
                    {/* <Route exact path="/coolParallax" render={(props) => <CoolParallax  {...props} />} /> */}
                    <Route
                      exact
                      path="/register/client"
                      render={(props) => <RegisterClient {...props} />}
                    />
                    <Route
                      exact
                      path="/teacher/prompt"
                      render={(props)=> <Prompt {...props} />}
                    />
                    <Route
                      exact
                      path="/teacher/signup"
                      render={(props) => <TeacherSignUp {...props} />}
                    />
                    <Route
                      exact
                      path="/teacher/signin"
                      render={(props) => <TeacherSignIn {...props} />}
                    />
                    <Route
                      exact
                      path="/teacher/home"
                      render={(props) => <Teacherhome {...props} />}
                    />
                    <Route
                      exact
                      path="/DemoPage"
                      render={(props) => <DemoPage {...props} />}
                    />
                    <Route
                      exact
                      path="/pupitDroid"
                      render={(props) => <Pupit {...props} />}
                    />
                    <Route
                      exact
                      path="/About"
                      render={(props) => <About {...props} />}
                    />
                    <Route
                      exact
                      path="/refundsandcancellations"
                      render={(props) => <CancellationRefund {...props} />}
                    />
                    <Route
                      exact
                      path="/termsandconditions"
                      render={(props) => <TermCondition {...props} />}
                    />
                    <Route
                      exact
                      path="/deleteUser"
                      render={(props) => <DeleteUser {...props} />}
                    />
                    {/* <Route
                      exact
                      path="/login/teacher"
                      component={TeacherLogin}
                    />  */}
                    <Route
                      exact
                      path="/signin"
                      render={(props) => <SignIn {...props} />}
                    />

                    {/* <Route exact path="/assessment/review" render={
                  (props) =>{!state.isAuthenticated ? <SignIn {...props} > : <ReviewAssessment {...props}/>}/</SignIn>

                  /> */}
                    {/* <PrivateRoute
                      authed={state.isAuthenticated}
                      path="/login"
                      component={ReviewAssessment}
                    /> */}
                    {/* <PrivateRoute2 path="/reviewAssessment" component={ReviewAssessment} /> */}
                    <Route
                      exact
                      path="/reviewQA"
                      render={(props) => <ReviewAssessment />}
                    />
                    <Route
                      exact
                      path="/createQA"
                      render={(props) => <CreateAssessment />}
                    />
                    <Route
                      exact
                      path="/createBook"
                      render={(props) => <CreateBook />}
                    />
                    <Route
                      exact
                      path="/createCourse"
                      render={(props) => <CreateCourse />}
                    />
                    <Route
                      exact
                      path="/privacy"
                      render={(props) => <PrivacyBlog {...props} />}
                    />
                    <Route
                      exact
                      path="/blog"
                      render={(props) => <ReasonAiBlog {...props} />}
                    />
                    <Route
                      exact
                      path="/pricing"
                      render={(props) => <Pricing {...props} />}
                    />
                    <Route
                      exact
                      path="/partners"
                      render={(props) => <Partner {...props} />}
                    />
                    <Route
                      exact
                      path="/Plan"
                      render={(props) => <Plan {...props} />}
                    />
                    <Route
                      exact
                      path="/PaymentSuccess"
                      render={(props) => <PaymentSuccess {...props} />}
                    />
                    <Route
                      exact
                      path="/PaymentFailure"
                      render={(props) => <PaymentFailure {...props} />}
                    />
                    {/* <Route
                      exact
                      path="/checkpaymentstatus"
                      render={(props) => < CheckPaymentStatus{...props} />}
                    /> */}
                    <Route
                      exact
                      path="/PendingPaymentPage"
                      render={(props) => <PendingPaymentPage {...props} />}
                    />
                    <Route
                      exact
                      path="/app-ads.txt"
                      render={()=> <iframe src='/app-ads.txt' title='app-ads.txt'/>}
                    />

                    {/* {pages.map((page, index) => {
                    return (
                      <Route
                        exact
                        path={page.pageLink}
                        render={({match}) => (
                          <page.view key={match.params.stateCode || index} />
                        )}
                        key={index}
                      />
                    );
                  })} */}
                    <Redirect to="/" />
                  </Switch>
                </React.Fragment>
              )}
            />
          </Suspense>
        </Router>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
