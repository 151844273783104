import LanguageSwitcher from './languageswitcher';

import anime from 'animejs';
import React, {useState, useRef} from 'react';
import * as Icon from 'react-feather';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
// import './respNavbar.css';
//import './navbar_resp.scss';
import $ from 'jquery';
import {
  useEffectOnce,
  useLockBodyScroll,
  useWindowSize,
  useLocalStorage,
} from 'react-use';

const navLinkProps = (path, animationDelay) => ({
  className: `fadeInUp ${window.location.pathname === path ? 'focused' : ''}`,
  style: {
    animationDelay: `${animationDelay}s`,
  },
});

const activeNavIcon = (path) => ({
  style: {
    stroke: window.location.pathname === path ? '#4c75f2' : '',
  },
});

// $(document).ready(function() {

//   $(window).scroll(function () {
//       //if you hard code, then use console
//       //.log to determine when you want the
//       //nav bar to stick.
//       console.log($(window).scrollTop())
//     if ($(window).scrollTop() > 1) {
//       //$('#nav').removeClass('nav');
//       $('#nav').addClass('sticky');
//       this.document.getElementById('logoWhite').style.display= 'none';
//       this.document.getElementById('logoDark').style.display= 'block';
//     }
//     if ($(window).scrollTop() < 1) {
//       $('#nav').removeClass('sticky');
//       //$('#nav').addClass('nav');
//       this.document.getElementById('logoDark').style.display= 'none';
//       this.document.getElementById('logoWhite').style.display= 'block';
//     }
//   });
// });

function ResponsiveNavbar({pages, darkMode, setDarkMode}) {
  const [expand, setExpand] = useState(false);
  // eslint-disable-next-line
  const [isThemeSet, setIsThemeSet] = useLocalStorage('isThemeSet', false);
  const {t} = useTranslation();

  useLockBodyScroll(expand);
  const windowSize = useWindowSize();

  return (
    <React.Fragment>
      <nav id="nav" className="nav">
        <button className="menu">
          <em className="hamburger" />
        </button>
        <div className="brand">
          {/* <a href="#">HertzAI</a> */}
          {/* <img id="logo" src="https://etime.hertzai.com/web/image/website/1/logo/HertzAI?unique=e737249" height="102" width="102"/> */}
          <a href="/">
            <img
              id="logoDark"
              src="https://etime.hertzai.com/web/image/website/1/logo/HertzAI?unique=e737249"
              height="52"
              width="52"
            />
            <img
              id="logoWhite"
              src="https://ai.hertzai.com/images/logo-dark.png"
              height="52"
              width="52"
            />
          </a>
        </div>
        <ul className="navbar">
          <li>
            <a href="/">
              <b>Home</b>
            </a>
          </li>
          <li>
            <a href="/">
              <b>Products</b>
            </a>
          </li>
          <li>
            <a href="/DemoPage" target="_blank">
              <b>Demo</b>
            </a>
          </li>
          <li>
            <a href="https://etime.hertzai.com/event">
              <b>Events</b>
            </a>
          </li>
          <li>
            <a href="https://etime.hertzai.com/forum">
              <b>Forum</b>
            </a>
          </li>
          <li>
            <a href="https://etime.hertzai.com/blog">
              <b>Blog</b>
            </a>
          </li>
          <li>
            <a href="https://etime.hertzai.com/jobs">
              <b>Careers</b>
            </a>
          </li>
          <li>
            <a href="https://etime.hertzai.com/slides">
              <b>Courses</b>
            </a>
          </li>
          <li>
            <a href="https://etime.hertzai.com/livechat">
              <b>Live Support</b>
            </a>
          </li>
          <li>
            <a href="https://etime.hertzai.com/contactus">
              <b>Pricing</b>
            </a>
          </li>
          <li>
            <a href="/home-business">
              <b>Zairo</b>
            </a>
          </li>
          <li>
            <a href="https://etime.hertzai.com/web/login">
              <b>Sign in</b>
            </a>
          </li>
        </ul>
      </nav>
      {/* <section className="section" style={{background: 'url(https://download.unsplash.com/photo-1422640805998-18a4dd89bec2)', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}> */}
      {/* <section className="section" style={{background: 'url(http://orig08.deviantart.net/5704/f/2014/053/6/5/free_space_galaxy_texture_by_lyshastra-d77gh18.jpg)', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}>
      </section>
      <section className="section" style={{background: 'url(http://orig08.deviantart.net/5704/f/2014/053/6/5/free_space_galaxy_texture_by_lyshastra-d77gh18.jpg)', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}>
      </section> */}
      {/* <div className="pattern-overlay" /> */}
    </React.Fragment>
  );
}

function Expand({
  expand,
  pages,
  setExpand,
  darkMode,
  setIsThemeSet,
  setDarkMode,
}) {
  const expandElement = useRef(null);
  const {t} = useTranslation();

  useEffectOnce(() => {
    anime({
      targets: expandElement.current,
      translateY: '1rem',
      easing: 'easeOutExpo',
      duration: 250,
    });
  });

  return (
    <div
      className="expand"
      ref={expandElement}
      onMouseLeave={() => {
        setExpand(false);
      }}
    >
      {/* <div> */}
      {pages.map((page, i) => {
        console.log('The page -> ' + page);
        console.log('The page counter -> ' + i);
        if (page.showInNavbar === true) {
          return (
            <Link
              to={page.pageLink}
              key={i}
              onClick={() => {
                setExpand(false);
              }}
            >
              <span
                {...navLinkProps(page.pageLink, page.animationDelayForNavbar)}
              >
                {t(page.displayName)}
              </span>
            </Link>
          );
        }
        return null;
      })}

      {window.innerWidth < 768 && (
        <div
          className="fadeInUp"
          style={{animationDelay: '0.9s'}}
          onClick={() => {
            setDarkMode((prevMode) => !prevMode);
            setIsThemeSet(true);
          }}
        >
          <div>{darkMode ? <Icon.Sun color={'#ffc107'} /> : <Icon.Moon />}</div>
        </div>
      )}

      <div className="expand-bottom fadeInUp" style={{animationDelay: '1s'}}>
        <h5>{t('A crowdsourced initiative.')}</h5>
      </div>
    </div>
  );
}

export default ResponsiveNavbar;
